import { fetcher } from '@lib/fetcher';

import { SEND_EMAIL_TO_ORG } from './SendMessageToOrg.mutation';
import { transformSendEmailToOrgData } from './SendMessageToOrg.transform';
import { Variables } from './types';

export default function useSendMessageToOrg() {
  function mutate(variables: Variables) {
    if (
      process.env.NEXT_PUBLIC_READ_ONLY &&
      process.env.NEXT_PUBLIC_READ_ONLY === 'true'
    ) {
      window.location.href =
        process.env.NEXT_PUBLIC_READ_ONLY_REDIRECT || '/404';
      throw new Error('Maintenance mode is on');
    }

    return fetcher(SEND_EMAIL_TO_ORG, variables).then((data) => {
      return transformSendEmailToOrgData(data);
    });
  }

  return { mutate };
}
